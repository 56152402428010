import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { PartRequest } from './partRequest';
import { CustomValidators } from 'app/shared/form/custom.validators';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class PartRequestService {
	private static readonly BASE_URL: string = BaseService.HOST + '/partRequests';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<PartRequest> {
		return this.http.get(PartRequestService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(PartRequestService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(partRequest: PartRequest): Observable<PartRequest> {
		return this.http.post(PartRequestService.BASE_URL, partRequest)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(partRequest: PartRequest): Observable<PartRequest> {
		return this.http.put(PartRequestService.BASE_URL + '/' + partRequest.id, partRequest)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(PartRequestService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(partRequests: PartRequest[]): Observable<any> {
		return this.http.post(PartRequestService.BASE_URL + '/delete', partRequests)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(partRequest: PartRequest): FormGroup {
		return this.fb.group({
			id: new FormControl(partRequest.id),
			quantity: new FormControl(partRequest.quantity, [CustomValidators.integerRegex, CustomValidators.min(0)]),
			price: new FormControl(partRequest.price, [CustomValidators.integerRegex, Validators.required, CustomValidators.min(0)]),
			description: new FormControl(partRequest.description),
			partOrder: new FormControl(partRequest.partOrder ? {id: partRequest.partOrder.id} : undefined, Validators.required),
			partModel: new FormControl(partRequest.partModel ? {id: partRequest.partModel.id} : undefined, Validators.required),
			/*serviceRequest: this.fb.group({
				id: new FormControl(partRequest.serviceRequest ? partRequest.serviceRequest.id : undefined)
			})*/
			serviceOrder: new FormControl(
				partRequest.serviceRequest && partRequest.serviceRequest.serviceOrder ? {id: partRequest.serviceRequest.serviceOrder.id} : undefined
			),
			serviceRequest: new FormControl(partRequest.serviceRequest ? {id: partRequest.serviceRequest.id} : undefined),
		});
	}
}
