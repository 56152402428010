import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { PartRequest } from './partRequest';
import { PartRequestService } from './partRequest.service';
import { PartModelService } from '../partModel/partModel.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { PartOrder } from 'app/partOrder/partOrder';
import { PartModel } from 'app/partModel/partModel';
import { HttpParams } from '@angular/common/http';
import { ServiceOrderService } from 'app/serviceOrder/serviceOrder.service';
import { ServiceOrder } from 'app/serviceOrder/serviceOrder';
import { ServiceRequest } from 'app/serviceRequest/serviceRequest';
import { ServiceRequestService } from 'app/serviceRequest/serviceRequest.service';
import { PartOrderService } from 'app/partOrder/partOrder.service';

@Component({
	template: `
		<h3 class="title" i18n="@@create-partRequest">Create Part</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-partRequest">Part data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="partModel" i18n="@@partModel">Part Model</label>
						<dropdown-ns [(control)]="form.controls['partModel']">
							<ng-template *ngFor="let object of partModels" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.name || '-'}}</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@code">Code</span>
											<span>{{object.code || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@price">Price</span>
											<span class="clickable-item" (click)="setPrice(object.price)">{{object.price || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@price2">Second price</span>
											<span class="clickable-item" (click)="setPrice(object.price2)">{{object.price2 || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@stock">Stock</span>
											<span>{{object.stock || '-'}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-ns>
						<validator [control]="form.controls['partModel']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="quantity" i18n="@@price">Price</label>
						<input formControlName="price" type="text" autocomplete="off">
						<validator [control]="form.controls['price']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="quantity" i18n="@@quantity">Quantity</label>
						<input formControlName="quantity" type="text" autocomplete="off">
						<validator [control]="form.controls['quantity']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-serviceRequest">Service</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="serviceOrder" i18n="@@serviceOrder">Order Service</label>
						<dropdown-ns [(control)]="form.controls['serviceOrder']" (changed)="changeServiceOrder($event)">
							<ng-template dropdownOption [value]="null">
								<span i18n="@@select-none">None</span>
							</ng-template>
							<ng-template *ngFor="let object of serviceOrders" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.id || '-'}}</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@company">Company</span>
											<span>{{object.store.company.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@store">Branch</span>
											<span>{{object.store.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@createdAt">created at</span>
											<span>{{object.createdAt || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@status">Status</span>
											<span translate>{{object.statusServiceOrder.name || '-'}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-ns>
					</div>
				</div>

				<div class="field-row" *ngIf="serviceRequests">
					<div class="field-row-item">
						<label class="label" for="serviceRequest" i18n="@@serviceRequest">Service</label>
						<dropdown-ns [(control)]="form.controls['serviceRequest']">
							<ng-template dropdownOption [value]="null">
								<span i18n="@@select-none">None</span>
							</ng-template>
							<ng-template *ngFor="let object of serviceRequests" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.id || '-'}}</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@serviceType">Service type</span>
											<span>{{object.serviceType?.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@machine-serial">Machine - serial</span>
											<span>{{object.machine?.serial || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-code">Machine Model - code</span>
											<span>{{object.machine?.machineModel?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-name">Machine Model - name</span>
											<span>{{object.machine?.machineModel?.comercialName || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineType">Machine Type</span>
											<span>{{object.machine?.machineModel?.machineType?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineBrand">Machine Brand</span>
											<span>{{object.machine?.machineModel?.machineBrand?.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@partRequests">Parts</span>
											<span>{{object.partRequests?.length || 0}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-ns>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class PartRequestCreateComponent implements OnInit {
	form: FormGroup;
	partModels: PartModel[];
	serviceOrders: ServiceOrder[];
	serviceRequests: ServiceRequest[];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private location: Location,
		private serviceOrderService: ServiceOrderService,
		private serviceRequestService: ServiceRequestService,
		private partOrderService: PartOrderService,
		private partRequestService: PartRequestService,
		private partModelService: PartModelService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			if (params.hasOwnProperty('partOrderId')) {
				let partRequest = new PartRequest();
				partRequest.partOrder = new PartOrder();
				partRequest.partOrder.id = params['partOrderId'];
				this.form = this.partRequestService.toFormGroup(partRequest);

				if (partRequest.serviceRequest) {
					this.getServiceRequests(partRequest.serviceRequest.serviceOrder);
				}

				this.partOrderService.getById(params['partOrderId']).subscribe(partOrder => {
					this.serviceOrderService.getAll(
						new HttpParams()
							.set('sort', 'id')
							.set('collection', 'id, , createdAt, store(id, name, company(id, name)), statusServiceOrder(id, name))')
							.set('storeId', partOrder.store.id.toString())
					).subscribe(data => {
						this.serviceOrders = data['result'];
					});
				});
			}
		});

		this.partModelService.getAll(
			new HttpParams()
				.set('sort', 'name')
				.set('collection', 'id, name, code, stock, price, price2')
		).subscribe(data => {
			this.partModels = data['result'];
		});
	}

	create() {
		this.partRequestService.create(<PartRequest> this.form.value).subscribe(partRequest => {
			this.notificationService.sucessInsert(partRequest.id.toString());
			this.location.back();
		}, err => this.notificationService.error(err));
	}

	setPrice(price: number) {
		this.form.patchValue({price: price});
	}

	getServiceRequests(serviceOrder: ServiceOrder) {
		this.serviceRequestService.getAll(
			new HttpParams()
				.set('sort', 'id')
				.set('collection', 'id, machine(id, serial, machineModel(id, name, comercialName, machineType(id, name), machineBrand(id, name))), serviceType(id, name), partRequests(id)')
				.set('serviceOrderId', serviceOrder.id.toString())
		).subscribe(data => {
			this.serviceRequests = data['result'];
		});
	}

	changeServiceOrder(serviceOrder: ServiceOrder) {
		this.form.patchValue({serviceRequest: null});
		if (serviceOrder) {
			this.getServiceRequests(serviceOrder);
		} else {
			delete this.serviceRequests;
		}
	}

	setServiceRequest(serviceRequest: ServiceRequest) {
		this.form.patchValue({serviceRequest: {id: serviceRequest.id}});
	}
}
