import { BaseModel } from 'app/core/models/base.model';
import { PartModel } from '../partModel/partModel';
import { PartOrder } from '../partOrder/partOrder';
import { ServiceRequest } from '../serviceRequest/serviceRequest';
import { ShipmentItem } from 'app/shipmentItem/shipmentItem';

export class PartRequest extends BaseModel {
	tracking: string;
	price: number;
	totalPrice: number;
	quantity = 1;
	description: string;
	shipped: boolean;
	partModel: PartModel;
	partOrder: PartOrder;
	serviceRequest: ServiceRequest;
	shipmentItems: ShipmentItem[];
	statusShipmentId: string;
}
