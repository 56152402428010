import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ServiceRequestService } from './serviceRequest.service';
import { MachineService } from '../machine/machine.service';
import { Machine } from '../machine/machine';
import { ServiceTypeService } from '../serviceType/serviceType.service';
import { ServiceType } from '../serviceType/serviceType';
import { ServiceOrderService } from '../serviceOrder/serviceOrder.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { ServiceRequest } from 'app/serviceRequest/serviceRequest';
import { HttpParams } from '@angular/common/http';
import { ServiceOrder } from '../serviceOrder/serviceOrder';

@Component({
	template: `
		<h3 class="title" i18n="@@create-serviceRequest">Create Service Order</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-serviceRequest">Service data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="serviceType" i18n="@@serviceType">Service Type</label>
						<mat-select [formControl]="form.controls['serviceType']" [compareWith]="compareFn" (selectionChange)="getMachines($event.value)" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of serviceTypes" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['serviceType']"></validator>
					</div>
				</div>
				<div class="field-row" *ngIf="machines">
					<div class="field-row-item">
						<label class="label" for="machines" i18n="@@machines">Machines</label>
						<dropdown-multi-ns [(control)]="form.controls['machines']">
							<ng-template *ngFor="let object of machines" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">
										<span class="clickable-item" (click)="getMachine(object)">{{object.serial || '-'}}</span>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@status">Status</span>
											<span translate>{{object.statusMachine?.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@usedDistance">Used distance (kms)</span>
											<span>{{object.usedDistance || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@maintenenceFrecuency">Maintenence frecuency</span>
											<span>{{object.maintenenceFrecuency || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@maintenencePerDistance">Maintenence per distance (kms)</span>
											<span>{{object.maintenencePerDistance || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@machineBrand">Machine Brand</span>
											<span>{{object.machineModel?.machineBrand?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineType">Machine Type</span>
											<span>{{object.machineModel?.machineType?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-code">Machine Model - code</span>
											<span>{{object.machineModel?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-name">Machine Model - name</span>
											<span>{{object.machineModel?.comercialName || '-'}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-multi-ns>
						<validator [control]="form.controls['machines']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="observation" i18n="@@observation">Observation</label>
						<textarea formControlName="observation"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`,
})
export class ServiceRequestCreateComponent implements OnInit {
	form: FormGroup;
	serviceTypes: ServiceType[];
	machines: Machine[];
	serviceOrder: ServiceOrder;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private serviceRequestService: ServiceRequestService,
		private serviceTypeService: ServiceTypeService,
		private machineService: MachineService,
		private serviceOrderService: ServiceOrderService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.parent.parent.params.subscribe(params => {
			if (params.hasOwnProperty('serviceOrderId')) {
				this.serviceOrderService.getById(params.serviceOrderId).subscribe(serviceOrder => {
					this.serviceOrder = serviceOrder;
					let serviceRequest = new ServiceRequest();
					serviceRequest.serviceOrder = serviceOrder;
					this.form = this.serviceRequestService.toFormGroup(serviceRequest);
				});
			}
		});

		this.serviceTypeService.getAll(
			new HttpParams().set('sort', 'name').set('collection', 'id, name')
		).subscribe(params2 => {
			this.serviceTypes = params2['result'];
		});
	}

	create() {
		let serviceRequests = this.form.value['machines'].map(machine => {
			let serviceRequest = new ServiceRequest();
			serviceRequest.observation = this.form.value['observation'];
			serviceRequest.serviceOrder = this.form.value['serviceOrder'];
			serviceRequest.serviceType = this.form.value['serviceType'];
			serviceRequest.machine = machine;
			return serviceRequest;
		});
		console.log(serviceRequests)
		this.serviceRequestService.creates(serviceRequests).subscribe(() => {
			this.notificationService.sucessInsert('serviceRequests');
			this.router.navigate([`./dashboard/serviceOrders/${this.form.value['serviceOrder'].id}`]);
		}, err => this.notificationService.error(err));
	}

	getMachines() {
		const serviceRequest = <ServiceRequest> this.form.value;
		if (serviceRequest.serviceType) {
			let httpParams = new HttpParams()
				.set('sort', 'serial')
				.set('collection', 'id, serial, usedDistance, maintenenceFrecuency, maintenencePerDistance, statusMachine(id, name), machineModel(id, name, comercialName, machineType(id, name), machineBrand(id, name))')
				.set('storeId', this.serviceOrder.store.id.toString())
				.set('serviceTypeId', serviceRequest.serviceType.id.toString());

			this.machineService.getAll(httpParams).subscribe(data => {
				this.machines = data['result'];
			});
		} else {
			this.machines = undefined;
		}
		this.form.controls['machines'].patchValue(undefined);
	}

	getMachine(machine: Machine) {
		this.router.navigate([`./${machine.id}`], {relativeTo: this.activatedRoute});
	}

	compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}
}
