
		<h3 class="title" i18n="@@detail-serviceOrder">Service Order Detail</h3>

		<ng-container *ngIf="serviceOrder">
			<div class="tool-bar-wrap both-side">
				<div class="right row">
					<ng-container *ngIf="serviceOrder.serviceTasksApprovedLength === 0 && !serviceOrder.mediaSignature; else deleteImage">
						<ng-template [hasPermission]="['MediaController.createServiceOrderSignature']">
							<input id="file-upload" type="file" accept="image/png, image/jpeg" (change)="updateImage($event)">
							<label class="btn-file" for="file-upload">
								<i class="material-icons">add_photo_alternate</i>
							</label>
						</ng-template>
					</ng-container>

					<ng-template #deleteImage>
						<ng-template [hasPermission]="['MediaController.delete']">
							<button *ngIf="serviceOrder.mediaSignature" class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDeleteMediaSignature = false">
								<i class="material-icons">delete</i>
								<span i18n="@signature">Signature</span>
							</button>
						</ng-template>
					</ng-template>

					<ng-template [hasPermission]="['ServiceOrders.delete']">
						<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
							<i class="material-icons">delete</i>
						</button>
					</ng-template>
				</div>
			</div>

			<div class="fieldset-wrap" style="margin-bottom: 30px;">
				<div class="fieldset both-side">
					<div>
						<div class="legend" i18n="@@data-serviceOrder">Service Order data</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@id">Id</span>
								<span>{{serviceOrder.id || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@company">Company</span>
								<span>{{serviceOrder.store?.company?.name || '-'}}</span>
							</div>
							<div class="field-row-item">
								<span class="label" i18n="@@store">Branch</span>
								<span>{{serviceOrder.store?.name || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@createdAt">Created date</span>
								<span>{{serviceOrder.createdAt | date:'medium' || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@progress">Progress</span>
								<span *ngIf="serviceOrder.progress != null && serviceOrder.progress >= 0 else noProgress">{{serviceOrder.progress}} %</span>
								<ng-template #noProgress>
									<span i18n="@@nothing-approved">Nothing approved</span>
								</ng-template>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@description">Description</span>
								<span>{{serviceOrder.description || '-'}}</span>
							</div>
						</div>
					</div>

					<div class="right row" *hasPermission="['ServiceOrders.updateStatusServiceOrder']; else elseBlock">
						<dropdown-ns [value]="{id: serviceOrder.statusServiceOrder.id}" (changed)="updateStatusServiceOrder($event)">
							<ng-template *ngIf="so_draft()" dropdownOption [value]="{id: 'so_draft'}">
								<span i18n="@@so_draft">Draft</span>
							</ng-template>
							<ng-template *ngIf="so_requested('selector')" dropdownOption [value]="{id: 'so_requested'}">
								<span i18n="@@so_requested">Requested</span>
							</ng-template>
							<ng-template *ngIf="so_approved('selector')" dropdownOption [value]="{id: 'so_approved'}">
								<span i18n="@@so_approved">Approved</span>
							</ng-template>
							<ng-template *ngIf="so_rejected('selector')" dropdownOption [value]="{id: 'so_rejected'}">
								<span i18n="@@so_rejected">Rejected</span>
							</ng-template>
							<ng-template *ngIf="so_cancelled()" dropdownOption [value]="{id: 'so_cancelled'}">
								<span i18n="@@so_cancelled">Cancelled</span>
							</ng-template>
							<ng-template *ngIf="so_closed('selector')" dropdownOption [value]="{id: 'so_closed'}">
								<span i18n="@@so_closed">Closed</span>
							</ng-template>
						</dropdown-ns>

						<button *ngIf="so_requested('button')"
							class="btn-status requested"
							(click)="updateStatusServiceOrder({id: 'so_requested'})"
							i18n="@@so_request">Request</button>
						<button *ngIf="so_approved('button')"
							class="btn-status approved"
							(click)="updateStatusServiceOrder({id: 'so_approved'})"
							i18n="@@so_approve">Approve</button>
						<button *ngIf="so_rejected('button')"
							class="btn-status rejected"
							(click)="updateStatusServiceOrder({id: 'so_rejected'})"
							i18n="@@so_reject">Reject</button>
						<button *ngIf="so_closed('button')"
							class="btn-status closed"
							(click)="updateStatusServiceOrder({id: 'so_closed'})"
							i18n="@@so_close">Close</button>
					</div>
					<ng-template #elseBlock>
						<div class="right" translate>{{serviceOrder.statusServiceOrder.name}}</div>
					</ng-template>
				</div>

				<div class="fieldset" *ngIf="serviceOrder.mediaSignature">
					<div class="legend" i18n="@@signature-data">Signature data</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@author">Author</span>
							<span>{{serviceOrder.mediaSignature?.name || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<gallery-image-preview-unique [item]="serviceOrder.mediaSignature"></gallery-image-preview-unique>
					</div>
				</div>
			</div>

			<serviceRequest-list
				[serviceOrder]="serviceOrder"
				(readServiceOrder)="read()">
			</serviceRequest-list>
		</ng-container>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>

		<modal-ns [(closed)]="confirmDeleteMediaSignature">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDeleteMediaSignature = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deleteMediaSignature(); confirmDeleteMediaSignature = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	