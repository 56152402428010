import { Component, OnInit, Input, SimpleChanges, OnChanges, Inject, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceRequest} from './serviceRequest';
import { ServiceRequestService } from './serviceRequest.service';
import { Status } from '../status/status';
import { NotificationService} from 'app/shared/notification/notification.service';
import { ServiceOrder } from 'app/serviceOrder/serviceOrder';
import { ServiceType } from 'app/serviceType/serviceType';
import { UserEmployee } from 'app/userEmployee/userEmployee';
import { HttpParams } from '@angular/common/http';
import { PartOrder } from 'app/partOrder/partOrder';
import { TableService } from 'app/core/models/pager';
import { ServiceRequestFilter } from './serviceRequest.filter';

@Component({
	selector: 'serviceRequest-list',
	template: `
		<div class="legend" i18n="@@serviceRequests">Services</div>

		<div class="tool-bar-wrap both-side">
			<ng-template [hasPermission]="['ServiceRequests.delete', 'ServiceTasks.create']">
				<div *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft' && items?.length > 0" style="padding: 10px;">
					<mat-checkbox (change)="tableService.masterToggle(items)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(items.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(items.length)">
					</mat-checkbox>
				</div>
			</ng-template>

			<div class="right row tool-bar-wrap">
				<ng-template [hasPermission]="['ServiceTasks.create']">
					<button *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft' && tableService.selection.hasValue()"
						class=" btn-icon-text" type="button" (click)="serviceTaskCreate = false">
						<span i18n="@@option-create-serviceTask">Create Job Order</span>
					</button>
				</ng-template>

				<ng-template [hasPermission]="['ServiceRequests.create']">
					<button *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'"
						class="btn-icon" i18n-title="@@option-create" title="Create" type="button" (click)="create()">
						<i class="material-icons">add</i>
					</button>
				</ng-template>

				<ng-template [hasPermission]="['ServiceRequests.delete']">
					<button *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft' && tableService.selection.hasValue()"
						class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
						<i class="material-icons">delete</i>
					</button>
				</ng-template>
			</div>
		</div>

		<div *ngIf="items?.length > 0 else empty" style="margin-top: 5px">
			<div *ngFor="let item of items" class="serviceRequest-container" (click)="tableService.selection.toggle(item)">
				<div class="table-row"
					(contextmenu)="tableService.selection.clear(); tableService.selection.select(item)"
					[contextMenu]="basicMenu" [contextMenuSubject]="item" [contextMenuSubject]="item">
					<ng-template [hasPermission]="['ServiceRequests.delete', 'ServiceTasks.create']">
						<div *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'"
							class="table-row-item container-checkbox">
							<mat-checkbox (click)="$event.stopPropagation()"
								(change)="tableService.selection.toggle(item)"
								[checked]="tableService.selection.isSelected(item)">
							</mat-checkbox>
						</div>
					</ng-template>
					<div class="table-row-item" style="flex: 0 0 auto;">
						<span class="label">#</span>
						<span class="clickable-item" (click)="read(item)">{{item.id || '-'}}</span>
					</div>
					<div class="table-row-item">
						<span class="label" i18n="@@machine-serial">Machine - serial</span>
						<span class="clickable-item" (click)="getMachine(item)">{{item.machine?.serial || '-'}}</span>
					</div>
					<div class="table-row-item" style="text-align: right;">
						<span class="label" i18n="@@serviceType">Type Service</span>
						<span>{{item.serviceType?.name || '-'}}</span>
					</div>
				</div>
				<div *ngIf="item.partRequests?.length > 0" class="extend">
					<div class="legend" i18n="@@partRequests">Parts</div>
					<div *ngFor="let partRequest of item.partRequests" class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@partOrder-id">Order Part - id</span>
							<span class="clickable-item" (click)="getPartOrder(partRequest.partOrder)">{{partRequest.partOrder?.id || '-'}}</span>
						</div>
						<div class="field-row-item">
							<span class="label" i18n="@@partRequest-id">Part - id</span>
							<span>{{partRequest.id || '-'}}</span>
						</div>
						<div class="field-row-item">
							<span class="label" i18n="@@partModel-name">Part Model - name</span>
							<span>{{partRequest.partModel?.name || '-'}}</span>
						</div>
						<div class="field-row-item">
							<span class="label" i18n="@@quantity">Quantity</span>
							<span>{{partRequest.quantity || '-'}}</span>
						</div>
						<div class="field-row-item">
							<span class="label" i18n="@@shipment">shipment</span>
							<span translate>{{partRequest.statusShipmentId || '-'}}</span>
						</div>
					</div>
				</div>

				<div class="footer both-side">
					<div class="field-row-item">
						<span class="label" i18n="@@progress">Progress</span>
						<span *ngIf="(item.progress != null && item.progress >= 0) else noProgress">{{item.progress}} %</span>
						<ng-template #noProgress>
							<span i18n="@@nothing-approved">Nothing approved</span>
						</ng-template>
					</div>
					<div class="right field-row" style="text-align: right;">
						<span class="field-row-item" style="text-transform: uppercase">
							<span class="label" i18n="@@total">total</span>
							<span>{{item.serviceTasksLength}}</span>
						</span>
					</div>
				</div>

				<serviceTask-list
					[serviceOrder]="serviceOrder"
					[serviceRequest]="item"
					[items]="item.serviceTasks"
					[userEmployees]="userEmployees"
					(listServiceRequest)="list()"
					(readServiceOrder)="onReadServiceOrder()">
				</serviceTask-list>
			</div>
		</div>

		<ng-template #empty>
			<div class="table-empty" i18n="@@no-records">Records not found</div>
		</ng-template>

		<context-menu #basicMenu *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'">
			<ng-template *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'"
				contextMenuItem (execute)="serviceTaskCreate = false">
				<div class="item">
					<i class="material-icons">add</i>
					<span i18n="option-create-serviceTask">Job Order</span>
				</div>
			</ng-template>
			<ng-container *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'">
				<ng-template contextMenuItem divider="true"></ng-template>
				<ng-template contextMenuItem (execute)="confirmDelete = false">
					<div class="item">
						<i class="material-icons">delete</i>
						<span i18n="@@option-delete">Delete</span>
					</div>
				</ng-template>
			</ng-container>
		</context-menu>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">Are you sucodere you want to delete the selected items?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>

		<modal-ns [(closed)]="serviceTaskCreate">
			<ng-template modalContentDirective>
				<div class="dialog-content" style="width:500px;">
					<serviceTask-create
						[serviceOrder]="serviceOrder"
						(listServiceRequest)="list()">
					</serviceTask-create>
				</div>
			</ng-template>
		</modal-ns>
	`,
	styles: [`
		.serviceRequest-container{
			border: 1px solid #e0e0e0;
			margin-bottom: 10px;
		}
		.table-row-items{
			display: flex;
			cursor: pointer;
		}
		.table-row-item,
		.extend,
		.footer{
			padding: 10px;
		}

		.footer{
			color: #b0b0b0;
		}
	`],
})
export class ServiceRequestListComponent implements OnInit, OnChanges {
	@Input() serviceOrder: ServiceOrder;
	@Output() readServiceOrder = new EventEmitter();
	items: ServiceRequest[];
	confirmDelete = true;
	serviceTypes: ServiceType[];
	userEmployees: UserEmployee[];
	statusServiceTasks: Status[];
	serviceTaskCreate = true;
	filter = new ServiceRequestFilter();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private serviceRequestService: ServiceRequestService,
		private notificationService: NotificationService,
		public tableService: TableService<ServiceRequest>
	) {}

	ngOnInit() {
		this.filter.serviceOrder = this.serviceOrder;
		this.list();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.serviceOrder.isFirstChange()) {
			this.list();
		}
	}

	onReadServiceOrder() {
		this.readServiceOrder.emit();
	}

	list() {
		let httpParams = new HttpParams()
			.set('sort', 'createdAt')
			.set('collection', 'id, progress, serviceTasksLength, serviceType(id, name), machine(id, serial), serviceTasks(id, startDateTime, userEmployee(id, firstName, lastName), statusServiceTask(id, name), serviceRecord(id, startDateTime, finishDateTime, description)), partRequests(id, quantity, statusShipmentId, partOrder(id), partModel(id, name))');
		httpParams = this.filter.getHttpParams(httpParams);

		this.serviceRequestService.getAll(httpParams).subscribe(data => {
			this.items = data['result'];
			this.tableService.pager = data['pager'];
			this.tableService.selection.clear();
			this.serviceTaskCreate = true;
		});
	}

	read(serviceRequest: ServiceRequest) {
		this.router.navigate(['./serviceRequests/' + serviceRequest.id], {relativeTo: this.activatedRoute});
	}

	getPartOrder(partOrder: PartOrder) {
		this.router.navigate([`./dashboard/partOrders/${partOrder.id}`]);
	}

	getMachine(serviceRequest: ServiceRequest) {
		this.router.navigate([`./machines/${serviceRequest.machine.id}`], {relativeTo: this.activatedRoute});
	}

	create() {
		this.router.navigate(['./serviceRequests/create'], {relativeTo: this.activatedRoute});
	}

	deletes() {
		const length = this.tableService.selection.selected.length;
		this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
			--this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;

		if (length === 1) {
			this.serviceRequestService.delete(this.tableService.selection.selected[0].id).subscribe(() => {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));
		} else if (length > 1) {
			this.serviceRequestService.deletes(this.tableService.selection.selected).subscribe(() => {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));
		}
	}
}
