import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceTask} from './serviceTask';
import { ServiceTaskService } from './serviceTask.service';
import { Status } from '../status/status';
import { UserEmployee } from 'app/userEmployee/userEmployee';
import { ServiceRequest } from '../serviceRequest/serviceRequest';
import { NotificationService} from 'app/shared/notification/notification.service';
import { ServiceOrder } from 'app/serviceOrder/serviceOrder';
import { ServiceTaskFilter } from './serviceTask.filter';
import { HttpParams } from '@angular/common/http';

@Component({
	selector: 'serviceTask-list',
	template: `
		<ul>
			<li *ngFor="let item of items" class="fieldset-wrap" (click)="read(item)">
				<div class="field-row both-side">
					<div class="field-row-item">
						<span class="label">#</span>
						<span>{{item.id}}</span>
					</div>

					<div class="field-row-item">
						<span class="label" i18n="@@startDateTime">Start Date Time</span>
						<span>{{item.startDateTime | date:'medium'  || '-'}}</span>
					</div>

					<div class="field-row-item">
						<span class="label" i18n="@@asignedTo">Asigned to</span>
						<span>{{item.userEmployee?.firstName || '-'}} {{item.userEmployee?.lastName}}</span>
					</div>

					<div class="right row" *hasPermission="['ServiceTasks.updateStatusServiceTask']; else elseBlock">
						<dropdown-ns
							[value]="{id: item.statusServiceTask.id}"
							(changed)="updateStatusServiceTask($event)"
							[disabled]="serviceOrder.statusServiceOrder.id !== 'so_approved'"
							(click)="$event.stopPropagation(); serviceTask = item;">
							<ng-template *ngIf="st_draft(item)" dropdownOption [value]="{id: 'st_draft'}">
								<span i18n="@@st_draft">Draft</span>
							</ng-template>
							<ng-template *ngIf="st_requested(item, 'selector')" dropdownOption [value]="{id: 'st_requested'}">
								<span i18n="@@st_requested">Requested</span>
							</ng-template>
							<ng-template *ngIf="st_approved(item, 'selector')" dropdownOption [value]="{id: 'st_approved'}">
								<span i18n="@@st_approved">Approved</span>
							</ng-template>
							<ng-template *ngIf="st_rejected(item, 'selector')" dropdownOption [value]="{id: 'st_rejected'}">
								<span i18n="@@st_rejected">Rejected</span>
							</ng-template>
							<ng-template *ngIf="st_done(item, 'selector')" dropdownOption [value]="{id: 'st_done'}">
								<span i18n="@@st_done">Done</span>
							</ng-template>
							<ng-template *ngIf="st_cancelled(item)" dropdownOption [value]="{id: 'st_cancelled'}">
								<span i18n="@@st_cancelled">Cancelled</span>
							</ng-template>
							<ng-template *ngIf="st_missed(item)" dropdownOption [value]="{id: 'st_missed'}">
								<span i18n="@@st_missed">Missed</span>
							</ng-template>
							<ng-template *ngIf="st_expired(item)" dropdownOption [value]="{id: 'st_expired'}">
								<span i18n="@@st_expired">Expired</span>
							</ng-template>
						</dropdown-ns>

						<button *ngIf="st_requested(item, 'button')"
							class="btn-status requested"
							(click)="$event.stopPropagation(); this.serviceTask = item; updateStatusServiceTask({id: 'st_requested'})"
							[disabled]="serviceOrder.statusServiceOrder.id !== 'so_approved'"
							i18n="@@st_request">Request</button>
						<button *ngIf="st_rejected(item, 'button')"
							class="btn-status rejected"
							(click)="$event.stopPropagation(); $event.stopPropagation(); this.serviceTask = item; updateStatusServiceTask({id: 'st_rejected'})"
							[disabled]="serviceOrder.statusServiceOrder.id !== 'so_approved'"
							i18n="@@st_reject">Reject</button>
						<button *ngIf="st_approved(item, 'button')"
							class="btn-status approved"
							(click)="$event.stopPropagation(); this.serviceTask = item; updateStatusServiceTask({id: 'st_approved'})"
							[disabled]="serviceOrder.statusServiceOrder.id !== 'so_approved'"
							i18n="@@st_approve">Approve</button>
						<button *ngIf="st_done(item, 'button')"
							class="btn-status done"
							(click)="$event.stopPropagation(); this.serviceTask = item; updateStatusServiceTask({id: 'st_done'})"
							[disabled]="serviceOrder.statusServiceOrder.id !== 'so_approved'"
							i18n="@@st_done">Done</button>

						<ng-template [hasPermission]="['ServiceTasks.update']">
							<button *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'" class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="$event.stopPropagation(); serviceTask = item; serviceTaskUpdate = false;">
								<i class="material-icons">edit</i>
							</button>
						</ng-template>

						<ng-template [hasPermission]="['ServiceTasks.delete']">
							<button *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'" class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="$event.stopPropagation();  serviceTask = item; confirmDelete = false;">
								<i class="material-icons">delete</i>
							</button>
						</ng-template>
					</div>
					<ng-template #elseBlock>
						<div class="right" translate>{{item.statusServiceTask.name}}</div>
					</ng-template>
				</div>
			</li>
		</ul>

		<modal-ns [(closed)]="serviceTaskUpdate">
			<ng-template modalContentDirective>
				<div class="dialog-content" style="width:500px;">
					<serviceTask-update
						[serviceTask]="serviceTask"
						(listServiceRequest)="list()">
					</serviceTask-update>
				</div>
			</ng-template>
		</modal-ns>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">Are you sure you want to delete the selected items?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`,
	styles: [`
		li{
			background-color: #f7f7f7;
			padding: 10px;
			cursor: pointer;
		}
		li:not(:last-child){
			margin-bottom: 1px;
		}
		.field-row-item{
			display: flex;
			align-items: center;
		}
		.serviceRecord{
			margin-right: 10px;
		}
	`],
})
export class ServiceTaskListComponent implements OnInit {
	@Input() items: ServiceTask[];
	@Input() serviceRequest: ServiceRequest;
	@Input() serviceOrder: ServiceOrder;
	@Input() userEmployees: UserEmployee;
	@Output() listServiceRequest = new EventEmitter();
	@Output() readServiceOrder = new EventEmitter();
	serviceTask: ServiceTask;
	confirmDelete = true;
	serviceTaskUpdate = true;
	filter = new ServiceTaskFilter();
	today = new Date().setHours(0, 0, 0, 0);

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private serviceTaskService: ServiceTaskService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.filter.serviceRequest = this.serviceRequest;
	}

	list(page?: number) {
		let httpParams = new HttpParams()
			.set('sort', 'startDateTime')
			.set('collection', 'id, startDateTime, userEmployee(id, firstName, lastName), statusServiceTask(id, name), serviceRecord(id, startDateTime, finishDateTime, description)');
		httpParams = this.filter.getHttpParams(httpParams);

		this.serviceTaskService.getAll(httpParams).subscribe(data => {
			this.items = data['result'];
		});
		this.serviceTaskUpdate = true;
	}

	read(serviceTask: ServiceTask) {
		this.router.navigate(['./serviceTasks/' + serviceTask.id], {relativeTo: this.activatedRoute});
	}

	updateUserEmployee(userEmployee: UserEmployee) {
		if (!userEmployee || userEmployee.id !== this.serviceTask.userEmployee.id) {
			this.serviceTask.userEmployee = userEmployee;

			this.serviceTaskService.updateUserEmployee(this.serviceTask).subscribe(obj => {
				this.serviceTask = obj;
				// this.notificationService.sucessUpdate(this.serviceTask.id.toString());
			}, err => {
				this.notificationService.error(err);
				this.list();
			});
		}
	}

	updateStatusServiceTask(status: Status) {
		if (status.id !== this.serviceTask.statusServiceTask.id) {
			this.serviceTask.statusServiceTask = status;

			this.serviceTaskService.updateStatusServiceTask(this.serviceTask).subscribe(serviceTask => {
				this.serviceRequest = serviceTask.serviceRequest;
				this.listServiceRequest.emit();
				this.readServiceOrder.emit();
				// this.notificationService.sucessUpdate(this.serviceRequest.id.toString());
			}, err => {
				this.notificationService.error(err);
				this.list();
			});
		}
	}

	delete() {
		this.serviceTaskService.delete(this.serviceTask.id).subscribe(data => {
			this.list();
			this.listServiceRequest.emit();
			this.readServiceOrder.emit();
			this.notificationService.sucessDelete();
		}, err => this.notificationService.error(err));
	}

	getServiceRecord(serviceTask: ServiceTask) {
		this.router.navigate(
			[`./${this.serviceRequest.id}/serviceTasks/${serviceTask.id}/serviceRecords/${serviceTask.serviceRecord.id}`],
			{relativeTo: this.activatedRoute}
		);
	}

	isExpired(item: ServiceTask) {
		return new Date(item.startDateTime).setHours(0, 0, 0, 0) < this.today;
	}

	st_draft(item) {
		return item.statusServiceTask.id === 'st_draft' ||
			item.statusServiceTask.id === 'st_approved' ||
			item.statusServiceTask.id === 'st_rejected' ||
			item.statusServiceTask.id === 'st_cancelled' ||
			item.statusServiceTask.id === 'st_missed' ||
			item.statusServiceTask.id === 'st_expired';
	}

	st_requested(item: ServiceTask, type: string) {
		if (type === 'selector') {
			return item.statusServiceTask.id === 'st_requested' || item.statusServiceTask.id === 'st_draft';
		} else if (type === 'button') {
			return item.statusServiceTask.id === 'st_draft';
		}
	}

	st_approved(item: ServiceTask, type: string) {
		if (type === 'selector') {
			return item.userEmployee &&
				(item.statusServiceTask.id === 'st_approved' ||
				item.statusServiceTask.id === 'st_requested' ||
				item.statusServiceTask.id === 'st_done');
		} else if (type === 'button') {
			return item.userEmployee && item.statusServiceTask.id === 'st_requested';
		}
	}

	st_rejected(item: ServiceTask, type: string) {
		if (type === 'selector') {
			return item.statusServiceTask.id === 'st_rejected' || item.statusServiceTask.id === 'st_requested';
		} else if (type === 'button') {
			return item.statusServiceTask.id === 'st_requested';
		}
	}

	st_done(item: ServiceTask, type: string) {
		if (type === 'selector') {
			return item.statusServiceTask.id === 'st_done' ||
				item.statusServiceTask.id === 'st_approved' ||
				item.statusServiceTask.id === 'st_missed';
		} else if (type === 'button') {
			return item.statusServiceTask.id === 'st_approved';
		}
	}

	st_cancelled(item: ServiceTask) {
		return item.statusServiceTask.id === 'st_cancelled' || item.statusServiceTask.id === 'st_approved';
	}

	st_missed(item: ServiceTask) {
		return item.statusServiceTask.id === 'st_missed' || (item.statusServiceTask.id === 'st_done' && this.isExpired(item));
	}

	st_expired(item: ServiceTask) {
		return item.statusServiceTask.id === 'st_expired' || (item.statusServiceTask.id !== 'st_done' && this.isExpired(item));
	}
}
