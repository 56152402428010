
		<h3 class="title" i18n="@@detail-serviceRequest">Service Order Detail</h3>

		<ng-container *ngIf="serviceRequest">
			<div class="tool-bar-wrap both-side">
				<div class="right row">
					<button *ngIf="serviceRequest.serviceOrder.statusServiceOrder.id === 'so_draft'" class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
						<i class="material-icons">delete</i>
					</button>
				</div>
			</div>

			<div class="fieldset-wrap">
				<div class="fieldset">
					<div class="legend" i18n="@@data-serviceRequest">Service data</div>

					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@id">Id</span>
							<span>{{serviceRequest.id || '-'}}</span>
						</div>
					</div>

					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@progress">Progress</span>
							<span *ngIf="serviceRequest.progress != null && serviceRequest.progress >= 0">{{serviceRequest.progress}} %</span>
							<span *ngIf="serviceRequest.progress == null || serviceRequest.progress < 0" i18n="@@nothing-approved">Nothing approved</span>
						</div>
					</div>

					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@createdAt">Created date</span>
							<span>{{serviceRequest.createdAt || '-'}}</span>
						</div>
					</div>

					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@observation">Observation</span>
							<span>{{serviceRequest.observation || '-'}}</span>
						</div>
					</div>
				</div>

				<div class="fieldset">
					<div class="legend" i18n="@@data-serviceType">Service Type data</div>

					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@name">Service Type</span>
							<span>{{serviceRequest.serviceType?.name || '-'}}</span>
						</div>
					</div>
				</div>

				<div class="fieldset">
					<div class="legend" i18n="@@data-machine">Machine data</div>

					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@serial">Serial</span>
							<span>{{serviceRequest.machine?.serial || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@machineModel-code">Machine Model - code</span>
							<span>{{serviceRequest.machine?.machineModel?.name || '-'}}</span>
						</div>
						<div class="field-row-item">
							<span class="label" i18n="@@machineModel-name">Machine Model - name</span>
							<span>{{serviceRequest.machine?.machineModel?.comercialName || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@machineType">Machine Type</span>
							<span>{{serviceRequest.machine?.machineModel?.machineType?.name || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@machineBrand">Machine Brand</span>
							<span>{{serviceRequest.machine?.machineModel?.machineBrand?.name || '-'}}</span>
						</div>
					</div>
				</div>

				<div class="fieldset">
					<div class="legend" i18n="@@data-store">Branch data</div>

					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@name">Name</span>
							<span>{{serviceRequest.machine?.store?.name || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@company">Company</span>
							<span>{{serviceRequest.machine?.store?.company?.name || '-'}}</span>
						</div>
					</div>
				</div>

				<!--<div class="fieldset">
					<div class="legend" i18n="@@data-serviceTasks">Job Orders data</div>

					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@st_requested">Requested</span>
							<span>{{serviceRequest.serviceTasksRequestedLength}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@st_approved">Approved</span>
							<span>{{serviceRequest.serviceTasksApprovedLength}}</span>
						</div>
						<div class="field-row-item">
							<span class="label" i18n="@@st_rejected">Rejected</span>
							<span>{{serviceRequest.serviceTasksRejectedLength}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@st_done">Done</span>
							<span>{{serviceRequest.serviceTasksDoneLength}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@st_cancelled">Cancelled</span>
							<span>{{serviceRequest.serviceTasksCancelledLength}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@st_missed">Missed</span>
							<span>{{serviceRequest.serviceTasksMissedLength}}</span>
						</div>
						<div class="field-row-item">
							<span class="label" i18n="@@st_expired">Expired</span>
							<span>{{serviceRequest.serviceTasksExpiredLength}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@total">Total</span>
							<span>{{serviceRequest.serviceTasksLength}}</span>
						</div>
					</div>
				</div>-->
			</div>
		</ng-container>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
						<br><br>
						<strong>WARNING!</strong> If yes, you will lose the associated Job Orders.
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	