import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserEmployee } from './userEmployee';
import { UserEmployeeService } from './userEmployee.service';
import { NotificationService} from 'app/shared/notification/notification.service';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-userEmployee">Employee User Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-update-password" title="Update password" type="button" (click)="updatePassword()" *hasPermission="['UserEployees.update']">
					<i class="material-icons">vpn_key</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()" *hasPermission="['UserEployees.update']">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *hasPermission="['UserEployees.delete']">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-account">Account data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@email">Email</span>
						<span>{{userEmployee.authUser?.email || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@authGroup">authGroup</span>
						<span>{{userEmployee.authUser?.authGroup?.name || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-userEmployee">Employee User data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@firstName">First name</span>
						<span>{{userEmployee.firstName || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@lastName">Last name</span>
						<span>{{userEmployee.lastName || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{userEmployee.description || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-contact">Contact data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@phone">Phone</span>
						<span>{{userEmployee.contact?.phone || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@phone2">Second phone</span>
						<span>{{userEmployee.contact?.phone2 || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@latitude">Latitude</span>
						<span>{{userEmployee.contact?.latitude || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@longitude">Longitude</span>
						<span>{{userEmployee.contact?.longitude || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@address">Address</span>
						<span>{{userEmployee.contact?.address || '-'}}</span>
					</div>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`
})
export class UserEmployeeReadComponent implements OnInit {
	confirmDelete = true;
	userEmployee = new UserEmployee();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private userEmployeeService: UserEmployeeService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.userEmployeeService.getById(params['userEmployeeId']).subscribe(userEmployee => {
				this.userEmployee = userEmployee;
			});
		});
	}

	/*getServiceRequests() {
		this.router.navigate(['./serviceRequests'], {relativeTo: this.activatedRoute});
	}*/

	update() {
		this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
	}

	updatePassword() {
		this.router.navigate(['./password'], {relativeTo: this.activatedRoute});
	}

	delete(this) {
		this.userEmployeeService.delete(this.userEmployee.id).subscribe(() =>  {
			this.notificationService.sucessDelete();
			let url = this.location.path();
			this.router.navigate([url.substring(0, url.lastIndexOf('/'))]);
		}, err => this.notificationService.error(err));
	}
}
