
		<ul>
			<li *ngFor="let item of items" class="fieldset-wrap" (click)="read(item)">
				<div class="field-row both-side">
					<div class="field-row-item">
						<span class="label">#</span>
						<span>{{item.id}}</span>
					</div>

					<div class="field-row-item">
						<span class="label" i18n="@@startDateTime">Start Date Time</span>
						<span>{{item.startDateTime | date:'medium'  || '-'}}</span>
					</div>

					<div class="field-row-item">
						<span class="label" i18n="@@asignedTo">Asigned to</span>
						<span>{{item.userEmployee?.firstName || '-'}} {{item.userEmployee?.lastName}}</span>
					</div>

					<div class="right row" *hasPermission="['ServiceTasks.updateStatusServiceTask']; else elseBlock">
						<dropdown-ns
							[value]="{id: item.statusServiceTask.id}"
							(changed)="updateStatusServiceTask($event)"
							[disabled]="serviceOrder.statusServiceOrder.id !== 'so_approved'"
							(click)="$event.stopPropagation(); serviceTask = item;">
							<ng-template *ngIf="st_draft(item)" dropdownOption [value]="{id: 'st_draft'}">
								<span i18n="@@st_draft">Draft</span>
							</ng-template>
							<ng-template *ngIf="st_requested(item, 'selector')" dropdownOption [value]="{id: 'st_requested'}">
								<span i18n="@@st_requested">Requested</span>
							</ng-template>
							<ng-template *ngIf="st_approved(item, 'selector')" dropdownOption [value]="{id: 'st_approved'}">
								<span i18n="@@st_approved">Approved</span>
							</ng-template>
							<ng-template *ngIf="st_rejected(item, 'selector')" dropdownOption [value]="{id: 'st_rejected'}">
								<span i18n="@@st_rejected">Rejected</span>
							</ng-template>
							<ng-template *ngIf="st_done(item, 'selector')" dropdownOption [value]="{id: 'st_done'}">
								<span i18n="@@st_done">Done</span>
							</ng-template>
							<ng-template *ngIf="st_cancelled(item)" dropdownOption [value]="{id: 'st_cancelled'}">
								<span i18n="@@st_cancelled">Cancelled</span>
							</ng-template>
							<ng-template *ngIf="st_missed(item)" dropdownOption [value]="{id: 'st_missed'}">
								<span i18n="@@st_missed">Missed</span>
							</ng-template>
							<ng-template *ngIf="st_expired(item)" dropdownOption [value]="{id: 'st_expired'}">
								<span i18n="@@st_expired">Expired</span>
							</ng-template>
						</dropdown-ns>

						<button *ngIf="st_requested(item, 'button')"
							class="btn-status requested"
							(click)="$event.stopPropagation(); this.serviceTask = item; updateStatusServiceTask({id: 'st_requested'})"
							[disabled]="serviceOrder.statusServiceOrder.id !== 'so_approved'"
							i18n="@@st_request">Request</button>
						<button *ngIf="st_rejected(item, 'button')"
							class="btn-status rejected"
							(click)="$event.stopPropagation(); $event.stopPropagation(); this.serviceTask = item; updateStatusServiceTask({id: 'st_rejected'})"
							[disabled]="serviceOrder.statusServiceOrder.id !== 'so_approved'"
							i18n="@@st_reject">Reject</button>
						<button *ngIf="st_approved(item, 'button')"
							class="btn-status approved"
							(click)="$event.stopPropagation(); this.serviceTask = item; updateStatusServiceTask({id: 'st_approved'})"
							[disabled]="serviceOrder.statusServiceOrder.id !== 'so_approved'"
							i18n="@@st_approve">Approve</button>
						<button *ngIf="st_done(item, 'button')"
							class="btn-status done"
							(click)="$event.stopPropagation(); this.serviceTask = item; updateStatusServiceTask({id: 'st_done'})"
							[disabled]="serviceOrder.statusServiceOrder.id !== 'so_approved'"
							i18n="@@st_done">Done</button>

						<ng-template [hasPermission]="['ServiceTasks.update']">
							<button *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'" class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="$event.stopPropagation(); serviceTask = item; serviceTaskUpdate = false;">
								<i class="material-icons">edit</i>
							</button>
						</ng-template>

						<ng-template [hasPermission]="['ServiceTasks.delete']">
							<button *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'" class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="$event.stopPropagation();  serviceTask = item; confirmDelete = false;">
								<i class="material-icons">delete</i>
							</button>
						</ng-template>
					</div>
					<ng-template #elseBlock>
						<div class="right" translate>{{item.statusServiceTask.name}}</div>
					</ng-template>
				</div>
			</li>
		</ul>

		<modal-ns [(closed)]="serviceTaskUpdate">
			<ng-template modalContentDirective>
				<div class="dialog-content" style="width:500px;">
					<serviceTask-update
						[serviceTask]="serviceTask"
						(listServiceRequest)="list()">
					</serviceTask-update>
				</div>
			</ng-template>
		</modal-ns>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">Are you sure you want to delete the selected items?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	