<div id="side-nav">
    <header>
        <img id="logo" src="./assets/images/logo_hec_mini.png" routerLink="/login"/>
    </header>

    <nav class="nav-item" *hasPermission="['ServiceOrders.findAll', 'PartOrders.findAll']">
        <nav class="nav-item">
            <div class="item header" (click)="selection.toggle(NAV_ORDERS)" [ngClass]="{'expanded': selection.isSelected(NAV_ORDERS), 'selected': dataMenuItem == 'serviceOrders' || dataMenuItem == 'partOrders'}">
                <span i18n="@@ordes">Orders</span>
                <i class="material-icons">keyboard_arrow_right</i>
            </div>
            <div class="nav-tab">
                <div class="item" data-link="./serviceOrders" data-menu-item="serviceOrders" [ngClass]="{'selected': dataMenuItem == 'serviceOrders'}" (click)="select($event)" routerLink="./serviceOrders" *hasPermission="['ServiceOrders.findAll']" i18n="@@serviceOrders">Service Orders</div>
                <div class="item" data-menu-item="partOrders" [ngClass]="{'selected': dataMenuItem == 'partOrders'}" (click)="select($event)" routerLink="./partOrders" *hasPermission="['PartOrders.findAll']" i18n="@@partOrders">Part Orders</div>
            </div>
        </nav>
        
        <nav class="nav-item" *hasPermission="['ServiceTypes.findAll', 'MachineTypes.findAll', 'MachineBrands.findAll', 'MachineModels.findAll', 'PartModels.findAll']">
            <div class="item header" (click)="selection.toggle(NAV_PRODUCTS_SERVICES)" [ngClass]="{'expanded': selection.isSelected(NAV_PRODUCTS_SERVICES), 'selected': dataMenuItem == 'serviceTypes' || dataMenuItem == 'machineTypes' || dataMenuItem == 'machineBrands' || dataMenuItem == 'machineModels' || dataMenuItem == 'partModels'}">
                <span i18n="@@products-services">Products and Services</span>
                <i class="material-icons">keyboard_arrow_right</i>
            </div>
            <div class="nav-tab">
                <div class="item" data-menu-item="serviceTypes" [ngClass]="{'selected': dataMenuItem == 'serviceTypes'}" (click)="select($event)" routerLink="./serviceTypes" *hasPermission="['ServiceTypes.findAll']" i18n="@@serviceTypes">Service Types</div>
                <div class="item" data-menu-item="machineTypes" [ngClass]="{'selected': dataMenuItem == 'machineTypes'}" (click)="select($event)" routerLink="./machineTypes" *hasPermission="['MachineTypes.findAll']" i18n="@@machineTypes">Machine Type</div>
                <div class="item" data-menu-item="machineBrands" [ngClass]="{'selected': dataMenuItem == 'machineBrands'}" (click)="select($event)" routerLink="./machineBrands" *hasPermission="['MachineBrands.findAll']" i18n="@@machineBrands">Machine Brand</div>
                <div class="item" data-menu-item="machineModels" [ngClass]="{'selected': dataMenuItem == 'machineModels'}" (click)="select($event)" routerLink="./machineModels" *hasPermission="['MachineModels.findAll']" i18n="@@machineModels">Machine Model</div>
                <div class="item" data-menu-item="partModels" [ngClass]="{'selected': dataMenuItem == 'partModels'}" (click)="select($event)" routerLink="./partModels" *hasPermission="['PartModels.findAll']" i18n="@@partModels">Part Models</div>
            </div>
        </nav>

        <nav class="nav-item" *hasPermission="['Companies.update', 'Stores.findAll']">
            <div class="item header" (click)="selection.toggle(NAV_COMPANIES_STORES)" [ngClass]="{'expanded': selection.isSelected(NAV_COMPANIES_STORES), 'selected': dataMenuItem == 'companies' || dataMenuItem == 'stores'}">
                <span i18n="@@companies-stores">Companies and Branchs</span>
                <i class="material-icons">keyboard_arrow_right</i>
            </div>
            <div class="nav-tab">
                <div class="item" data-menu-item="companies" [ngClass]="{'selected': dataMenuItem == 'companies'}" (click)="select($event)" routerLink="./companies" *hasPermission="['Companies.update']" i18n="@@companies">Companies</div>   
                <div class="item" data-menu-item="stores" [ngClass]="{'selected': dataMenuItem == 'stores'}" (click)="select($event)" routerLink="./stores" *hasPermission="['Stores.findAll']" i18n="@@stores">Branchs</div>         
            </div>
        </nav>
        
        <nav class="nav-item" *hasPermission="['UserClients.update', 'UserEmployees.update', 'UserGenerics.update']">
            <div class="item header" (click)="selection.toggle(NAV_USERS)" [ngClass]="{'expanded': selection.isSelected(NAV_USERS), 'selected': dataMenuItem == 'userClients' || dataMenuItem == 'userEmployees' || dataMenuItem == 'userGenerics'}">
                <span i18n="@@users">Users</span>
                <i class="material-icons">keyboard_arrow_right</i>
            </div>
            <div class="nav-tab">
                <div class="item" data-menu-item="userClients" [ngClass]="{'selected': dataMenuItem == 'userClients'}" (click)="select($event)" routerLink="./userClients" *hasPermission="['UserClients.update']" i18n="@@userClients">Client Users</div>
                <div class="item" data-menu-item="userEmployees" [ngClass]="{'selected': dataMenuItem == 'userEmployees'}" (click)="select($event)" routerLink="./userEmployees" *hasPermission="['UserEmployees.update']" i18n="@@userEmployees">Employee Users</div>
                <div class="item" data-menu-item="userGenerics" [ngClass]="{'selected': dataMenuItem == 'userGenerics'}" (click)="select($event)" routerLink="./userGenerics" *hasPermission="['UserGenerics.update']" i18n="@@userGenerics">Generic Users</div>
            </div>
        </nav>
    </nav>
</div>		