import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseService } from 'app/core/base.service';
import { ServiceRequest } from './serviceRequest';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ServiceRequestService {
	private static readonly BASE_URL: string = BaseService.HOST + '/serviceRequests';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<ServiceRequest> {
		return this.http.get(ServiceRequestService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(ServiceRequestService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(serviceRequest: ServiceRequest): Observable<ServiceRequest> {
		return this.http.post(ServiceRequestService.BASE_URL, serviceRequest)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	creates(serviceRequests: ServiceRequest[]): Observable<ServiceRequest[]> {
		return this.http.post(ServiceRequestService.BASE_URL, serviceRequests)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(serviceRequest: ServiceRequest): Observable<ServiceRequest> {
		return this.http.put(ServiceRequestService.BASE_URL + '/' + serviceRequest.id, serviceRequest)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(ServiceRequestService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(serviceRequests: ServiceRequest[]): Observable<any> {
		return this.http.post(ServiceRequestService.BASE_URL + '/delete', serviceRequests)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(serviceRequest: ServiceRequest): FormGroup {
		return this.fb.group({
			id: new FormControl(),
			observation: new FormControl(serviceRequest.observation),
			serviceOrder: new FormControl(serviceRequest.serviceOrder ? {id: serviceRequest.serviceOrder.id} : undefined, Validators.required),
			serviceType: new FormControl(serviceRequest.serviceType ? {id: serviceRequest.serviceType.id} : undefined, Validators.required),
			machines: new FormControl(null, Validators.required),
		});
	}
}
