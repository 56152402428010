import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ServiceRecord } from './serviceRecord';
import { ServiceRecordService } from './serviceRecord.service';
import { ServiceTaskService } from '../serviceTask/serviceTask.service';
import { NotificationService} from 'app/shared/notification/notification.service';

@Component({
	template: `
		<h3 class="title" i18n="@@create-serviceRecord">Create Order Record</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-serviceRecord">Order Record data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="startDateTime" i18n="@@startDateTime">Start Date Time</label>
						<div class="datepicker">
							<input matInput [matDatepicker]="picker" [formControl]="form.controls['startDateTime']">
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						</div>
						<mat-datepicker #picker></mat-datepicker>
						<validator [control]="form.controls['startDateTime']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="finishDateTime" i18n="@@finishDateTime">Finish Date Time</label>
						<div class="datepicker">
							<input matInput [matDatepicker]="picker2" [formControl]="form.controls['finishDateTime']">
							<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						</div>
						<mat-datepicker #picker2></mat-datepicker>
						<validator [control]="form.controls['finishDateTime']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
						<validator [control]="form.controls['description']"></validator>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`,
})
export class ServiceRecordCreateComponent implements OnInit {
	form: FormGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private serviceRecordService: ServiceRecordService,
		private serviceTaskService: ServiceTaskService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			if (params.hasOwnProperty('serviceTaskId')) {
				this.serviceTaskService.getById(params['serviceTaskId']).subscribe(serviceTask => {
					let serviceRecord = new ServiceRecord();
					serviceRecord.serviceTask = serviceTask;
					serviceRecord.finishDateTime = serviceRecord.startDateTime = serviceTask.startDateTime;
					this.form = this.serviceRecordService.toFormGroup(serviceRecord);
				});
			}
		});
	}

	create() {
		let obj = this.form.value;
		if (obj.startDateTime) {
			obj.startDateTime = obj.startDateTime.format('YYYY-MM-DD') + 'T00:00:01-04';
		}
		if (obj.finishDateTime) {
			obj.finishDateTime = obj.finishDateTime.format('YYYY-MM-DD') + 'T00:00:01-04';
		}
		this.serviceRecordService.create(<ServiceRecord> obj).subscribe(serviceRecord => {
			this.notificationService.sucessInsert(serviceRecord.id.toString());
			this.router.navigate([`../${serviceRecord.id}`], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}
}
