import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PartOrder } from './partOrder';
import { PartOrderService } from './partOrder.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { Status } from 'app/status/status';
import * as moment from 'moment';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-partOrder">Part Order Detail</h3>

		<ng-container *ngIf="partOrder">
			<div class="tool-bar-wrap both-side">
				<div class="right row">
					<ng-template [hasPermission]="['PartOrders.findAll']">
						<button class="btn-icon-text" [ngClass]="{selected: !shipmentListView}" type="button" (click)="shipmentListView = false">
							<span i18n="@@partRequests">Parts</span>
						</button>
					</ng-template>
					<ng-template [hasPermission]="['Shipments.findAll']">
						<button class="btn-icon-text" [ngClass]="{selected: shipmentListView}" type="button" (click)="shipmentListView = true">
							<span i18n="@@shipments">Shipments</span>
						</button>
					</ng-template>
					<ng-template [hasPermission]="['PartOrders.delete']">
						<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
							<i class="material-icons">delete</i>
						</button>
					</ng-template>
				</div>
			</div>

			<div class="fieldset-wrap both-side">
				<div class="fieldset">
					<div class="legend" i18n="@@data-partOrder">Part Order data</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@id">Id</span>
							<span>{{partOrder.id || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@company">Company</span>
							<span>{{partOrder.store?.company?.name || '-'}}</span>
						</div>
						<div class="field-row-item">
							<span class="label" i18n="@@store">Branch</span>
							<span>{{partOrder.store?.name || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@createdAt">Created date</span>
							<span>{{partOrder.createdAt | date:'medium' || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@description">Description</span>
							<span>{{partOrder.description || '-'}}</span>
						</div>
					</div>
				</div>

				<div class="right row" *hasPermission="['PartOrders.updateStatusPartOrder']; else elseBlock">
					<dropdown-ns
						[value]="{id: partOrder.statusPartOrder.id}"
						(changed)="updateInventory($event)">
						<ng-template *ngIf="po_draft()" dropdownOption [value]="{id: 'po_draft'}">
							<span i18n="@@po_draft">Draft</span>
						</ng-template>
						<ng-template *ngIf="po_requested('selector')" dropdownOption [value]="{id: 'po_requested'}">
							<span i18n="@@po_requested">Requested</span>
						</ng-template>
						<ng-template *ngIf="po_approved('selector')" dropdownOption [value]="{id: 'po_approved'}">
							<span i18n="@@po_approved">Approved</span>
						</ng-template>
						<ng-template *ngIf="po_rejected('selector')" dropdownOption [value]="{id: 'po_rejected'}">
							<span i18n="@@po_rejected">Rejected</span>
						</ng-template>
						<ng-template *ngIf="po_cancelled()" dropdownOption [value]="{id: 'po_cancelled'}">
							<span i18n="@@po_cancelled">Cancelled</span>
						</ng-template>
						<ng-template *ngIf="po_closed('selector')" dropdownOption [value]="{id: 'po_closed'}">
							<span i18n="@@po_closed">Closed</span>
						</ng-template>
					</dropdown-ns>

					<button *ngIf="po_requested('button')"
						class="btn-status requested"
						(click)="updateStatusPartOrder({id: 'po_requested'})"
						i18n="@@po_request">Request</button>
					<button *ngIf="po_rejected('button')"
						class="btn-status rejected"
						(click)="updateStatusPartOrder({id: 'po_rejected'})"
						i18n="@@po_reject">Reject</button>
					<button *ngIf="po_approved('button')"
						class="btn-status approved"
						(click)="confirmInventory = false"
						i18n="@@po_approve">Approve</button>
					<button *ngIf="po_closed('button')"
						class="btn-status closed"
						(click)="updateStatusPartOrder({id: 'po_closed'})"
						i18n="@@po_close">Close</button>
				</div>
				<ng-template #elseBlock>
					<div class="right" translate>{{partOrder.statusPartOrder.name}}</div>
				</ng-template>
			</div>

			<shipment-list *ngIf="shipmentListView else partRequestList"
				[partOrder]="partOrder"
				[(hasShipmentInTransit)]="hasShipmentInTransit"
				[(hasShipmentInReturn)]="hasShipmentInReturn"
				[(hasShipmentDelivered)]="hasShipmentDelivered">confirmInventory = false
			</shipment-list>

			<ng-template #partRequestList>
				<partRequest-list [partOrder]="partOrder"></partRequest-list>
			</ng-template>
		</ng-container>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>

		<modal-ns [(closed)]="confirmInventory">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm-inventory">Inventory update</div>
					<div class="dialog-message" i18n="@@confirm-inventory-disccount">
						If you approve the order then the products will be discounted from the stock. Are you sure continue?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmInventory = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="updateStatusPartOrder({id: 'po_approved'}); confirmInventory = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>

		<modal-ns [(closed)]="confirmInventory2">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm-inventory">Inventory update</div>
					<div class="dialog-message" i18n="@@confirm-inventory-restore">
						If you pass the order to draft or cancel then the products will be restored from the stock. Are you sure continue?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmInventory2 = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="updateStatusPartOrder(status); confirmInventory2 = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`,
})
export class PartOrderReadComponent implements OnInit {
	confirmDelete = true;
	confirmInventory = true;
	confirmInventory2 = true;
	status: Status;
	shipmentListView: boolean;
	partOrder: PartOrder;
	id: number;
	hasShipmentInTransit = false;
	hasShipmentInReturn = false;
	hasShipmentDelivered = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private partOrderService: PartOrderService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.id = params['partOrderId'];
		});
		this.read();
	}

	read() {
		this.partOrderService.getById(this.id).subscribe(partOrder => {
			this.partOrder = partOrder;
			this.partOrder.createdAt = moment(this.partOrder.createdAt).toISOString().toString(); // the angular pipe failed with the raw date, ie: 2018-11-07T17:00:17-04 (for the -04)
			this.shipmentListView = this.partOrder.statusPartOrder.id === 'po_approved' ? true : false;
		});
	}

	updateStatusPartOrder(status: Status) {
		if (status.id !== this.partOrder.statusPartOrder.id) {
			this.partOrder.statusPartOrder = status;

			this.partOrderService.updateStatusPartOrder(this.partOrder).subscribe(partOrder => {
				this.partOrder = partOrder;
				this.partOrder.createdAt = moment(this.partOrder.createdAt).toISOString().toString(); // the angular pipe failed with the raw date, ie: 2018-11-07T17:00:17-04 (for the -04)
				if (partOrder.statusPartOrder.id === 'po_approved') {
					//this.shipmentListView = true;
				}
				//this.notificationService.sucessUpdate(this.partRequest.id.toString());
			}, err => {
				this.notificationService.error(err);
				this.read();
			});
		}
	}

	delete() {
		this.partOrderService.delete(this.partOrder.id).subscribe(data => {
			this.notificationService.sucessDelete();
			let url = this.location.path();
			this.router.navigate([url.substring(0, url.lastIndexOf('/'))]);
		}, err => this.notificationService.error(err));
	}

	updateInventory(status) {
		switch (this.partOrder.statusPartOrder.id) {
			case 'po_requested':
				if (status.id === 'po_approved') {
					this.confirmInventory = false;
				}
				break;
			case 'po_approved':
				if (status.id === 'po_cancelled' || status.id === 'po_draft') {
					this.confirmInventory2 = false;
					this.status = status;
				}
				break;
			default:
				this.updateStatusPartOrder(status);
		}
	}

	po_draft() {
		return this.partOrder.statusPartOrder.id === 'po_draft' ||
			this.partOrder.statusPartOrder.id === 'po_approved' ||
			this.partOrder.statusPartOrder.id === 'po_rejected' ||
			this.partOrder.statusPartOrder.id === 'po_cancelled';
	}

	po_requested(type: string) {
		if (type === 'selector') {
			return this.partOrder.statusPartOrder.id === 'po_requested' || this.partOrder.statusPartOrder.id === 'po_draft';
		} else if (type === 'button') {
			return this.partOrder.statusPartOrder.id === 'po_draft';
		}
	}

	po_approved(type: string) {
		if (type === 'selector') {
			return this.partOrder.statusPartOrder.id === 'po_approved' ||
				this.partOrder.statusPartOrder.id === 'po_requested' ||
				this.partOrder.statusPartOrder.id === 'po_closed';
		} else if (type === 'button') {
			return this.partOrder.statusPartOrder.id === 'po_requested';
		}
	}

	po_rejected(type: string) {
		if (type === 'selector') {
			return (this.partOrder.statusPartOrder.id === 'po_rejected' || this.partOrder.statusPartOrder.id === 'po_requested') &&
				!this.hasShipmentInTransit &&
				!this.hasShipmentDelivered &&
				!this.hasShipmentInReturn;
		} else if (type === 'button') {
			return this.partOrder.statusPartOrder.id === 'po_requested' &&
				!this.hasShipmentInTransit &&
				!this.hasShipmentDelivered &&
				!this.hasShipmentInReturn;
		}
	}

	po_cancelled() {
		return (this.partOrder.statusPartOrder.id === 'po_cancelled' || this.partOrder.statusPartOrder.id === 'po_approved') &&
			!this.hasShipmentInTransit &&
			!this.hasShipmentDelivered &&
			!this.hasShipmentInReturn;
	}

	po_closed(type: string) {
		if (type === 'selector') {
			return (this.partOrder.statusPartOrder.id === 'po_closed' || this.partOrder.statusPartOrder.id === 'po_approved') &&
				!this.hasShipmentInTransit &&
				!this.hasShipmentInReturn;
		} else if (type === 'button') {
			return this.partOrder.statusPartOrder.id === 'po_approved' &&
				!this.hasShipmentInTransit &&
				!this.hasShipmentInReturn;
		}
	}
}
