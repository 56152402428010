import { BaseModel } from 'app/core/models/base.model';
import { Machine } from '../machine/machine';
import { ServiceType } from '../serviceType/serviceType';
import { ServiceTask } from 'app/serviceTask/serviceTask';
import { ServiceOrder } from 'app/serviceOrder/serviceOrder';
import { User } from 'app/user/user';

export class ServiceRequest extends BaseModel {
	progress: number;
	price: number;
	totalPrice: number;
	observation: string;
	serviceType: ServiceType;
	machine: Machine;
	serviceOrder: ServiceOrder;
	updatedBy: User;
	serviceTasks: ServiceTask[] = new Array<ServiceTask>();
	serviceTasksLength: number;
	serviceTasksRequestedLength: number;
	serviceTasksApprovedLength: number;
	serviceTasksRejectedLength: number;
	serviceTasksDoneLength: number;
	serviceTasksCancelledLength: number;
	serviceTasksMissedLength: number;
	serviceTasksExpiredLength: number;
	partRequestsLength: number;
}
