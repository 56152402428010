import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { FormsModule }  from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { UserGenericModule } from 'app/userGeneric/userGeneric.module';
import { UserClientModule } from 'app/userClient/userClient.module';
import { UserEmployeeModule } from 'app/userEmployee/userEmployee.module';
import { CompanyModule } from 'app/company/company.module';
import { StoreModule } from 'app/store/store.module';
import { MachineBrandModule } from 'app/machineBrand/machineBrand.module';
import { MachineTypeModule } from 'app/machineType/machineType.module';
import { MachineModelModule } from 'app/machineModel/machineModel.module';
import { PartModelModule } from 'app/partModel/partModel.module';
import { ServiceTypeModule } from 'app/serviceType/serviceType.module';
import { ServiceOrderModule } from 'app/serviceOrder/serviceOrder.module';
import { PartOrderModule } from '../partOrder/partOrder.module';
import { BreadcrumbsComponent } from 'app/core/breadcrumbs/breadcrumbs.component';
import { TopbarComponent } from 'app/core/topbar/topbar.component';
import { DashboardComponent } from './dashboard.component';
import { dashboardRouting } from './dashboard.routes';
import { SidebarComponent } from 'app/core/sidebar/sidebar.component';
import { UserModule } from 'app/user/user.module';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';

@NgModule({
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		FormsModule,
		SharedModule,
		UserModule,
		UserGenericModule,
		UserClientModule,
		UserEmployeeModule,
		CompanyModule,
		StoreModule,
		MachineBrandModule,
		MachineTypeModule,
		MachineModelModule,
		PartModelModule,
		ServiceTypeModule,
		ServiceOrderModule,
		PartOrderModule,
		dashboardRouting,
		NgProgressModule.forRoot(),
		NgProgressHttpModule.forRoot()
	],
	declarations: [
		BreadcrumbsComponent,
		TopbarComponent,
		DashboardComponent,
		SidebarComponent
	]
})
export class DashboardModule { }
