import { Component, Input, Output, EventEmitter, ContentChildren, QueryList } from '@angular/core';
import { DropdownOptionDirective } from 'app/shared/form/dropdown-ns/dropdownOptionDirective';
import { Selector } from './selector-ns';
import { AbstractControl } from '@angular/forms';

// reference: https://github.com/CuppaLabs/angular2-multiselect-dropdown
@Component({
	selector: 'dropdown-multi-ns',
	template: `
		<div class="dd" [ngClass]="{'disabled': disabled}" (clickOutside)="hidden = true">
			<div class="select dd-select" (click)="hidden = !hidden">
				<div *ngIf="selector.length() === 1" class="container-data">
					<ng-template [ngTemplateOutlet]="getOptionByValue(selector.getItems()[0])?.template"></ng-template>
				</div>
				<div *ngIf="selector.length() > 1" class="container-data" i18n="@@select-multiple">Multiple</div>
			</div>

			<div class="dd-options" *ngIf="!disabled" [hidden]="hidden">
				<div class="header" *ngIf="options.length > 0">
					<div class="dd-options-item">
						<div class="container-checkbox">
							<mat-checkbox (change)="onMasterToggle()"
								[checked]="selector.hasValue() && selector.isAllSelected(getValuesFromOptions())"
								[indeterminate]="selector.hasValue() && !selector.isAllSelected(getValuesFromOptions())"
								*hasPermission="['ServiceTypes.delete']">
							</mat-checkbox>
						</div>
						<div class="container-data" (click)="onMasterToggle()" i18n="@@select-selectAll">Select all</div>
					</div>
				</div>
				<div class="body">
					<ul>
						<li *ngFor="let option of options" class="dd-options-item" [ngClass]="{'selected': selector.isSelected(option.value)}" (click)="onToggle(option.value)">
							<div class="container-checkbox">
								<mat-checkbox (click)="$event.stopPropagation()"
									(change)="onToggle(option.value)"
									[checked]="selector.isSelected(option.value)"
									*hasPermission="['ServiceTypes.delete']">
								</mat-checkbox>
							</div>
							<div class="container-data">
								<ng-template [ngTemplateOutlet]="option.template"></ng-template>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	`,
	styleUrls: ['dropdown-ns.component.css'],
})
export class DropdownMultiComponent {
	@Input() disabled = false;

	_control: AbstractControl;
	@Input()
	set control(control: AbstractControl) {
		this._control = control;
		this.selector.selectMulti(control.value);
	}
	get control(){ return this._control; }
	@Output() controlChange = new EventEmitter();

	@Input()
	get value() { return this.selector.getItems(); }
	set value(values: any) {
		this.selector.selectMulti(values);
	}

	@Output() changed = new EventEmitter();
	@ContentChildren(DropdownOptionDirective) public options: QueryList<DropdownOptionDirective>;
	selector = new Selector();
	hidden = true;

	onToggle(value: any) {
		this.selector.toggle(value);
		const values = this.selector.getItems();
		if (this.control) {
			this.control.patchValue(values);
			this.controlChange.emit(this.control);
		}
		this.changed.emit(values);
	}

	onMasterToggle() {
		let values = [];
		this.options.forEach(option => values.push(option.value));

		this.selector.masterToggle(values);
		if (this.control) {
			this.control.setValue(values);
			this.controlChange.emit(this.control);
		}
		this.changed.emit(values);
	}

	getOptionByValue(value): any {
		const a = this.options.find(option => this.isEqual(option.value, value));
		return a;
	}

	getValuesFromOptions(): any {
		let values = [];
		this.options.forEach(option => values.push(option.value));
		return values;
	}

	private isEqual(objA, objB) {
		return JSON.stringify(objA) === JSON.stringify(objB);
	}
}
