import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { UserClientService } from './userClient.service';
import { UserService } from '../user/user.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { UserClient } from 'app/userClient/userClient';
import { AuthUser } from '../auth/authUser';

@Component({
	template: `
		<h3 class="title" i18n="@@update-pass">Update password</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
			<fieldset class="fieldset" formGroupName="authUser">
				<legend class="legend" i18n="@@data-account">Account data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="password" i18n="@@password">Password</label>
						<input formControlName="password" type="password" autocomplete="off">
						<validator [control]="form.controls['authUser'].controls['password']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="passwordConfirm" i18n="@@passwordConfirm">Password confirm</label>
						<input formControlName="passwordConfirm" type="password" autocomplete="off">
						<validator [control]="form.controls['authUser'].controls['passwordConfirm']"></validator>
					</div>
				</div>
			</fieldset>
			
			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class UserClientUpdatePasswordComponent implements OnInit {
	form: FormGroup;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private userClientService: UserClientService,
		private userService: UserService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			let userClient = new UserClient();
			userClient.id = params['userClientId'];
			userClient.authUser = new AuthUser();
			this.form = this.userClientService.toFormGroupUpdatePassword(userClient);
		});
	}

	update() {
		this.userService.updatePassword(this.form.value).subscribe(userClient => {
			this.notificationService.sucessUpdate(userClient.firstName + ' ' + userClient.lastName);
			this.router.navigate([`../`], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}
}
