
		<h3 class="title" i18n="@@create-partRequest">Create Part</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-partRequest">Part data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="partModel" i18n="@@partModel">Part Model</label>
						<dropdown-ns [(control)]="form.controls['partModel']">
							<ng-template *ngFor="let object of partModels" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.name || '-'}}</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@code">Code</span>
											<span>{{object.code || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@price">Price</span>
											<span class="clickable-item" (click)="setPrice(object.price)">{{object.price || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@price2">Second price</span>
											<span class="clickable-item" (click)="setPrice(object.price2)">{{object.price2 || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@stock">Stock</span>
											<span>{{object.stock || '-'}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-ns>
						<validator [control]="form.controls['partModel']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="quantity" i18n="@@price">Price</label>
						<input formControlName="price" type="text" autocomplete="off">
						<validator [control]="form.controls['price']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="quantity" i18n="@@quantity">Quantity</label>
						<input formControlName="quantity" type="text" autocomplete="off">
						<validator [control]="form.controls['quantity']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-serviceRequest">Service</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="serviceOrder" i18n="@@serviceOrder">Order Service</label>
						<dropdown-ns [(control)]="form.controls['serviceOrder']" (changed)="changeServiceOrder($event)">
							<ng-template dropdownOption [value]="null">
								<span i18n="@@select-none">None</span>
							</ng-template>
							<ng-template *ngFor="let object of serviceOrders" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.id || '-'}}</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@company">Company</span>
											<span>{{object.store.company.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@store">Branch</span>
											<span>{{object.store.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@createdAt">created at</span>
											<span>{{object.createdAt || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@status">Status</span>
											<span translate>{{object.statusServiceOrder.name || '-'}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-ns>
					</div>
				</div>

				<div class="field-row" *ngIf="serviceRequests">
					<div class="field-row-item">
						<label class="label" for="serviceRequest" i18n="@@serviceRequest">Service</label>
						<dropdown-ns [(control)]="form.controls['serviceRequest']">
							<ng-template dropdownOption [value]="null">
								<span i18n="@@select-none">None</span>
							</ng-template>
							<ng-template *ngFor="let object of serviceRequests" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.id || '-'}}</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@serviceType">Service type</span>
											<span>{{object.serviceType?.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@machine-serial">Machine - serial</span>
											<span>{{object.machine?.serial || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-code">Machine Model - code</span>
											<span>{{object.machine?.machineModel?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-name">Machine Model - name</span>
											<span>{{object.machine?.machineModel?.comercialName || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineType">Machine Type</span>
											<span>{{object.machine?.machineModel?.machineType?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineBrand">Machine Brand</span>
											<span>{{object.machine?.machineModel?.machineBrand?.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@partRequests">Parts</span>
											<span>{{object.partRequests?.length || 0}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-ns>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	