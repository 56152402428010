
		<h3 class="title" i18n="@@update-pass">Update password</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
			<fieldset class="fieldset" formGroupName="authUser">
				<legend class="legend" i18n="@@data-account">Account data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="password" i18n="@@password">Password</label>
						<input formControlName="password" type="password" autocomplete="off">
						<validator [control]="form.controls['authUser'].controls['password']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="passwordConfirm" i18n="@@passwordConfirm">Password confirm</label>
						<input formControlName="passwordConfirm" type="password" autocomplete="off">
						<validator [control]="form.controls['authUser'].controls['passwordConfirm']"></validator>
					</div>
				</div>
			</fieldset>
			
			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	