
		<h3 class="title" i18n="@@serviceOrders">Service Orders</h3>

		<mat-accordion>
			<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
				<mat-expansion-panel-header>
					<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
						<div class="field-row">
							<div class="field-row-item" *ngIf="tableService.filter.id">
								<span class="label" i18n="@@id">id</span>
								<span>{{tableService.filter.id}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.statusServiceOrder">
								<span class="label" i18n="@@status">Status</span>
								<span>{{tableService.filter.statusServiceOrder.name}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.company">
								<span class="label" i18n="@@company">Company</span>
								<span>{{tableService.filter.company.name}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.store">
								<span class="label" i18n="@@store">Branch</span>
								<span>{{tableService.filter.store.name}}</span>
							</div>
						</div>
					</div>
					<button class="btn-icon" i18n-title="@@option-search" title="Search" type="button">
						<i class="material-icons">search</i>
					</button>
				</mat-expansion-panel-header>

				<form>
					<fieldset class="fieldset">
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="id" i18n="@@id">Id</label>
								<input type="text" name="id" placeholder="None" i18n-placeholder="None" [(ngModel)]="filter.id">
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="statusServiceOrder" i18n="@@status">Status</label>
								<mat-select placeholder="None" i18n-placeholder="None" [(value)]="filter.statusServiceOrder" [compareWith]="compareFn" disableRipple>
									<mat-option i18n="@@select-none">None</mat-option>
									<mat-option *ngFor="let opt of statusServiceOrders" [value]="opt">{{opt.name}}</mat-option>
								</mat-select>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="company" i18n="@@company">Company</label>
								<mat-select placeholder="None" i18n-placeholder="None" [(value)]="filter.company" [compareWith]="compareFn" (selectionChange)="changeCompany($event.value)"  disableRipple>
									<mat-option i18n="@@select-none">None</mat-option>
									<mat-option *ngFor="let opt of companies" [value]="opt">{{opt.name}}</mat-option>
								</mat-select>
							</div>
							<div class="field-row-item">
								<label class="label" for="store" i18n="@@store">Branch</label>
								<mat-select placeholder="None" i18n-placeholder="None" [(value)]="filter.store" [compareWith]="compareFn" (selectionChange)="changeStore($event.value)" disableRipple>
									<mat-option i18n="@@select-none">None</mat-option>
									<mat-option *ngFor="let opt of stores" [value]="opt">{{opt.name}}</mat-option>
								</mat-select>
							</div>
						</div>
					</fieldset>
					<div class="options">
						<button class="btn-text gray" type="button" (click)="reset()">
							<span i18n="@@option-reset">Reset</span>
						</button>
						<button class="btn-text blue" type="button" (click)="search()">
							<span i18n="@@option-search">Search</span>
						</button>
					</div>
				</form>
			</mat-expansion-panel>
		</mat-accordion>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-create" type="button" (click)="create()" *hasPermission="['ServiceOrders.create']">
					<i class="material-icons">add</i>
				</button>
				<ng-template [hasPermission]="['ServiceOrders.delete']">
					<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
						<i class="material-icons">delete</i>
					</button>
				</ng-template>
			</div>
		</div>

		<div class="mat-elevation-z8e" *ngIf="dataSource">
			<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
							[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
							[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)"
							*hasPermission="['ServiceOrders.delete']">
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="tableService.selection.toggle(row)"
							[checked]="tableService.selection.isSelected(row)"
							*hasPermission="['ServiceOrders.delete']">
						</mat-checkbox>
					</td>
				</ng-container>

				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@id">Id</th>
					<td mat-cell *matCellDef="let element">{{element.id}}</td>
				</ng-container>

				<ng-container matColumnDef="store.company.name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@company">Company</th>
					<td mat-cell *matCellDef="let element">{{element.store?.company?.name}}</td>
				</ng-container>

				<ng-container matColumnDef="store.name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@store">Branch</th>
					<td mat-cell *matCellDef="let element">{{element.store?.name}}</td>
				</ng-container>

				<ng-container matColumnDef="createdAt">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdAt">Created date</th>
					<td mat-cell *matCellDef="let element">{{element.createdAt | date:'medium'}}</td>
				</ng-container>

				<ng-container matColumnDef="statusServiceOrder.name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@status">Status</th>
					<td mat-cell *matCellDef="let element" translate>{{element.statusServiceOrder?.name}}</td>
				</ng-container>

				<ng-container matColumnDef="progress">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@progress">Progress</th>
					<td mat-cell *matCellDef="let element" translate translate>{{element.progress}} <span *ngIf="element.progress">%</span></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"
					(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
					(click)="read(row)">
				</tr>
			</table>

			<mat-paginator showFirstLastButtons
				[length]="tableService.pager.totalEntities"
				[pageIndex]="tableService.pager.pageIndex"
                [pageSize]="tableService.pager.pageSize"
				[pageSizeOptions]="[20, 50, 100]"
				(page)="list($event)">
			</mat-paginator>
		</div>

		<context-menu #basicMenu>
			<ng-template [hasPermission]="['ServiceOrders.delete']">
				<ng-template contextMenuItem (execute)="confirmDelete = false">
					<div class="item">
						<i class="material-icons">delete</i>
						<span i18n="@@option-delete">Delete</span>
					</div>
				</ng-template>
			</ng-template>
		</context-menu>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">Are you sure you want to delete the selected items?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>statusServiceOrder
		</modal-ns>
	